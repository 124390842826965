/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Sidebar } from "../../../../components/Admin/Sidebar";
import { Navbar } from "../../../../components/Admin/Navbar";
import { ReactComponent as IconSoal } from "../../../../assets/admin/svg/ic_soal.svg";
import { HiDotsVertical } from "react-icons/hi";
import { BsSearch, BsFillPlusCircleFill } from "react-icons/bs";
import ManajemenSoalController, {
	useManajemenSoalContext,
} from "../../../../controller/admin/manajemen_soal/index";
import { PaketSoal } from "../../../../../../entities/PaketSoal";
import { If, Then, Else } from "react-if";
import Shimmer from "react-shimmer-effect";
import ReactPaginate from "react-paginate";
import { ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";
import Select from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import { Box, Grid } from "@chakra-ui/react";
import env from "@ludovicm67/react-dotenv";
import EditorComponent from "../../../../components/Admin/Editor";

const BottomHeader: React.FC = () => {
	const {
		searchPaketSoal,
		setFormDialog,
		setShowDialog,
		filterTahun,
		filterKategoriSoal,
		filterJenisSoal,
	} = useManajemenSoalContext();
	const { kategoriPaketSoal } = useManajemenSoalContext().state;

	var tahun: number[] = [];

	for (var i = 2000; i <= 2099; i++) {
		tahun.push(i);
	}

	var optionsTahun;

	if (tahun.length > 0) {
		optionsTahun = tahun.map((item) => ({ value: item, label: item }));
	} else {
		optionsTahun = [];
	}

	var optionsKategoriSoal;

	if (kategoriPaketSoal.length > 0) {
		optionsKategoriSoal = kategoriPaketSoal.map((item) => ({
			value: item.id,
			label: item.kategori_paket_soal,
		}));
	} else {
		optionsKategoriSoal = [];
	}

	var optionsJenisSoal;

	optionsJenisSoal = [
		{ value: "Pilihan Ganda", label: "Pilihan Ganda" },
		{ value: "Pilihan Ganda Kompleks", label: "Pilihan Ganda Kompleks" },
	];

	const clearFilter = () => {
		filterTahun("");
		filterKategoriSoal("");
		filterJenisSoal("");
	};

	return (
		<div className="w-full bg-white">
			<div className="grid grid-cols-1 lg:grid-cols-2">
				<div className="grid grid-cols-4 gap-2 items-center ml-5">
					{/* Filter Tahun */}
					<Select
						className="w-full"
						options={optionsTahun}
						onChange={(val: any) => filterTahun(val.value)}
						placeholder="Tahun Soal"
					/>

					{/* Filter Kategori */}
					<Select
						className="w-full"
						options={optionsKategoriSoal}
						onChange={(val: any) => filterKategoriSoal(val.value)}
						placeholder="Kategori Soal"
					/>

					{/* Filter Jenis Soal */}
					<Select
						className="w-full"
						options={optionsJenisSoal}
						onChange={(val: any) => filterJenisSoal(val.value)}
						placeholder="Jenis Soal"
					/>

					{/* Clear Filter */}
					<button
						onClick={clearFilter}
						className="px-4 py-2.5 rounded border border-green-300 text-green-500 font-semibold text-xs flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease hover:bg-green-600 hover:text-white"
					>
						Clear Filter
					</button>
				</div>
				<div className="flex flex-wrap items-center mr-5 ml-5 justify-self-end">
					<div className="input-group relative flex flex-wrap mt-6 mr-2">
						<button
							className="btn px-3 py-1 border border-gray-300 text-[#212529] text-lg leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
							style={{ height: "38px" }}
						>
							<BsSearch />
						</button>
						<input
							type="search"
							className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white  border border-solid border-gray-300 rounded focus:ring-0 focus:border-gray-300"
							placeholder="Search"
							onChange={(event) =>
								searchPaketSoal(event.target.value)
							}
						/>
					</div>
					<button
						type="button"
						className="flex px-6 py-2.5 bg-green-600 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-green-700 mb-3 mt-3"
						onClick={() => {
							setFormDialog("Tambah");
							setShowDialog(true);
						}}
					>
						Tambah Baru
						<BsFillPlusCircleFill
							className="ml-2"
							style={{ marginTop: "2px" }}
						/>
					</button>
				</div>
			</div>
		</div>
	);
};

const ItemSoal = ({ paket }: { paket: PaketSoal }) => {
	const { setFormDialog, setUpdate, setShowDialog, setShowDialogDelete } =
		useManajemenSoalContext();
	return (
		<div className="sm:col-span-3 md:col-span-2 lg:col-span-1 pl-2 mb-5 w-full">
			<div
				className="rounded-lg bg-white max-w-sm hover:shadow-lg transition-all duration-300"
				style={{ height: "150px" }}
			>
				<div
					className="rounded-t-lg p-4 flex justify-between mb-5"
					style={{ backgroundColor: "#F2F2F2", height: "70px" }}
				>
					<a href={"detail/manajemen_soal/" + paket.id}>
						<div className="flex">
							<IconSoal />
							<h4 className="text-sm ml-2 font-medium">
								{paket.judul_soal.length > 55
									? paket.judul_soal.substring(0, 55) + "..."
									: paket.judul_soal}
							</h4>
						</div>
					</a>
					<div className="dropdown relative">
						<a
							className="dropdown-toggle flex items-center hidden-arrow"
							href="#"
							id="dropdownMenuButton2"
							role="button"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<HiDotsVertical />
						</a>
						<ul
							className="
                            dropdown-menu
                            min-w-max
                            absolute
                            hidden
                            bg-white
                            text-base
                            z-40
                            float-left
                            py-2
                            list-none
                            text-left
                            rounded-lg
                            shadow-lg
                            mt-1
                            hidden
                            m-0
                            bg-clip-padding
                            border-none
                            left-auto
                            right-0
                        "
							aria-labelledby="dropdownMenuButton2"
						>
							<li>
								<a
									className="
                                dropdown-item
                                text-sm
                                py-2
                                px-4
                                font-normal
                                w-full
                                whitespace-nowrap
                                bg-transparent
                                text-gray-700
                                hover:bg-gray-100
                                cursor-pointer
                            "
									onClick={() => {
										setFormDialog("Ubah");
										setShowDialog(true);
										setUpdate(paket);
									}}
								>
									Ubah
								</a>
							</li>
							<li>
								<a
									className="
                                dropdown-item
                                text-sm
                                py-2
                                px-4
                                font-normal
                                w-full
                                whitespace-nowrap
                                bg-transparent
                                text-gray-700
                                hover:bg-gray-100
                                cursor-pointer
                            "
									onClick={() => {
										setShowDialogDelete(true);
										setUpdate(paket);
									}}
								>
									Hapus
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="px-3 flex flex-wrap justify-between">
					<span className="px-3 py-2 rounded-full border border-green-300 text-green-500 font-semibold text-xs flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
						{paket.kategori_paket_soal.kategori_paket_soal}
					</span>
					<a className="text-sm mt-2">
						({paket.soal.length} Soal Pilihan Ganda)
					</a>
				</div>
			</div>
		</div>
	);
};

const ModalCreateData: React.FC = () => {
	const {
		formDialog,
		formJenisSoal,
		formJudul,
		formKategori,
		formTahun,
		formLoading,
		showDialog,
		kategoriPaketSoal,
		formPetunjukSoal,
	} = useManajemenSoalContext().state;
	const {
		actionFormDialog,
		setFormJenisSoal,
		setFormJudul,
		setFormKategori,
		setFormTahun,
		setFormPetunjukSoal,
		setShowDialog,
	} = useManajemenSoalContext();

	var tahun: number[] = [];

	for (var i = 2000; i <= 2099; i++) {
		tahun.push(i);
	}

	return (
		<>
			{showDialog ? (
				<>
					{/* Background Modal */}
					<div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

					{/* Modal */}
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
							<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
								{/* Modal content */}
								<div className="flex items-start justify-between p-4 rounded-t">
									<h3 className="text-xl font-semibold text-gray-900">
										{formDialog} Soal
									</h3>
									<button
										type="button"
										className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
										onClick={() => setShowDialog(false)}
										style={{ color: "white" }}
									>
										<svg
											className="w-5 h-5"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									</button>
								</div>
								<div className="p-6 space-y-6 -mt-2">
									<div className="form-group grid grid-cols-6">
										<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
											Judul Soal
										</label>
										<input
											type="text"
											className="
                        form-control
                        col-span-4
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                      "
											placeholder="Masukkan Judul Soal"
											value={formJudul}
											onChange={(e) =>
												setFormJudul(e.target.value)
											}
										/>
									</div>
									<div className="form-group grid grid-cols-6">
										<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
											Tahun
										</label>
										<select
											className="form-control
                      form-select-xs
                      col-span-4
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
											value={formTahun}
											onChange={(e) =>
												setFormTahun(e.target.value)
											}
										>
											<option value={""}>
												Pilih Tahun
											</option>
											{tahun.map((t) => {
												return (
													<option value={t}>
														{t}
													</option>
												);
											})}
										</select>
									</div>
									<div className="form-group grid grid-cols-6">
										<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
											Ketegori
										</label>
										<select
											className="form-control
                      form-select-xs
                      col-span-4
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
											value={formKategori}
											onChange={(e) =>
												setFormKategori(e.target.value)
											}
										>
											<option value={""}>
												Pilih Kategori
											</option>
											<If
												condition={
													kategoriPaketSoal.length > 0
												}
											>
												<Then>
													{kategoriPaketSoal.map(
														(k) => {
															return (
																<option
																	value={k.id}
																>
																	{
																		k.kategori_paket_soal
																	}
																</option>
															);
														}
													)}
												</Then>
											</If>
										</select>
									</div>
									<div className="form-group grid grid-cols-6">
										<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
											Jenis Soal
										</label>
										<select
											className="form-control
                      form-select-xs
                      col-span-4
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
											value={formJenisSoal}
											onChange={(e) =>
												setFormJenisSoal(e.target.value)
											}
										>
											<option value={""}>
												Pilih Jenis Soal
											</option>
											<option value={"Pilihan Ganda"}>
												Pilihan Ganda
											</option>
											<option
												value={"Pilihan Ganda Kompleks"}
											>
												Pilihan Ganda Kompleks
											</option>
										</select>
									</div>
									<div className="form-group grid grid-cols-6">
										<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
											Petunjuk Soal
										</label>
										<div className="col-span-4">
											<EditorComponent
												description={`${formPetunjukSoal}`}
												setDescription={(a: any) =>
													setFormPetunjukSoal(a)
												}
												withKey={false}
											/>
										</div>
									</div>
								</div>
								<div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
										onClick={() => setShowDialog(false)}
									>
										Batal
									</button>
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
										onClick={() => actionFormDialog()}
									>
										<If condition={formLoading}>
											<Then>
												<ReactLoading
													type={"bars"}
													color={"white"}
													height={15}
													width={20}
												/>
											</Then>
											<Else>Simpan</Else>
										</If>
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

const ModalDelete: React.FC = () => {
	const { showDialogDelete, formLoading } = useManajemenSoalContext().state;
	const { setShowDialogDelete, deletePaketSoal } = useManajemenSoalContext();

	return (
		<>
			{showDialogDelete ? (
				<>
					{/* Background Modal */}
					<div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

					{/* Modal */}
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
							<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
								{/* Modal content */}
								<div className="flex items-start justify-between p-4 rounded-t">
									<h3 className="text-xl font-semibold text-gray-900">
										Hapus Soal
									</h3>
									<button
										type="button"
										className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
										onClick={() =>
											setShowDialogDelete(false)
										}
										style={{ color: "white" }}
									>
										<svg
											className="w-5 h-5"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									</button>
								</div>
								<div className="p-6 space-y-6 -mt-2">
									Apakah Anda Yakin Ingin Menghapus Data Ini?
								</div>
								<div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
										onClick={() =>
											setShowDialogDelete(false)
										}
									>
										Batal
									</button>
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
										onClick={() => deletePaketSoal()}
									>
										<If condition={formLoading}>
											<Then>
												<ReactLoading
													type={"bars"}
													color={"white"}
													height={15}
													width={20}
												/>
											</Then>
											<Else>Yakin</Else>
										</If>
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

const Pagination = () => {
	const { setPage } = useManajemenSoalContext();
	const { jumlahPage } = useManajemenSoalContext().state;

	var endFix =
		jumlahPage > Number(jumlahPage.toFixed())
			? Number(jumlahPage.toFixed()) + 1
			: Number(jumlahPage.toFixed());

	const handlePageClick = (event) => {
		setPage(event.selected);
	};

	return (
		<If condition={endFix > 1}>
			<Then>
				<div className="flex justify-center mt-5">
					<ReactPaginate
						breakLabel="..."
						nextLabel="Selanjutnya"
						activeClassName="active"
						containerClassName="pagination"
						onPageChange={handlePageClick}
						marginPagesDisplayed={2}
						pageRangeDisplayed={2}
						pageCount={
							jumlahPage > Number(jumlahPage.toFixed())
								? Number(jumlahPage.toFixed()) + 1
								: Number(jumlahPage.toFixed())
						}
						previousLabel="Sebelumnya"
					/>
				</div>
			</Then>
			<Else>
				<></>
			</Else>
		</If>
	);
};

const WrapperMain: React.FC = () => {
	const { getPaketSoal, getListKategoriPaketSoal } =
		useManajemenSoalContext();
	const { paketSoal, isLoading } = useManajemenSoalContext().state;

	useEffect(() => {
		getPaketSoal(1, "");
		getListKategoriPaketSoal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<SidebarWithHeader title="Manajemen Soal">
			<Box className="app">
				<ModalCreateData />
				<ModalDelete />

				<Box as="main" style={{ padding: "0px" }}>
					<Box mb={4}>
						<BottomHeader />
					</Box>

					<Box mb={10}>
						<Grid
							templateColumns={{
								base: "1fr",
								sm: "repeat(1, 1fr)",
								md: "repeat(4, 1fr)",
								lg: "repeat(3, 1fr)",
							}}
							gap={4}
						>
							<If condition={isLoading}>
								<Then>
									{[1, 2, 3, 4, 5, 6].map((i) => (
										<Shimmer key={i}>
											<Box
												pl={2}
												mt={2}
												w="full"
												borderRadius="lg"
												mx={3}
												h="100px"
											></Box>
										</Shimmer>
									))}
								</Then>
								<Else>
									{paketSoal && Array.isArray(paketSoal) ? (
										paketSoal.map((p: PaketSoal) => (
											<ItemSoal key={p.id} paket={p} />
										))
									) : (
										<></>
									)}
								</Else>
							</If>
						</Grid>

						<Pagination />
					</Box>
				</Box>
			</Box>
		</SidebarWithHeader>
	);
};

const ManajemenSoal: React.FC = () => {
	return (
		<ManajemenSoalController.Provider>
			<ToastContainer
				position="top-center"
				style={{ width: "60%", marginTop: "5%" }}
			/>
			<WrapperMain />
		</ManajemenSoalController.Provider>
	);
};

export default ManajemenSoal;
