/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import {
	AiOutlineSave,
	AiOutlinePlus,
	HiDotsVertical,
	ImFilePlay,
	AiOutlinePlusCircle,
} from "react-icons/all";
import ImgUsers from "../../../../assets/png/img_users.png";
import { ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";
import KelolaKontenManajemenPelatihanController, {
	useKelolaKontenManajemenPelatihanContext,
} from "../../../../controller/admin/manajemen_pelatihan/kelola_konten";
import { If, Then, Else } from "react-if";
import { ModulPelatihan } from "../../../../../../entities/ModulPelatihan";
import { AiFillCloseCircle } from "react-icons/ai";
import ReactAudioPlayer from "react-audio-player";
import parse from "html-react-parser";
import { Jawaban } from "../../../../../../entities/PaketSoal";
import Switch from "react-switch";
import { JawabanQuiz } from "../../../../../../entities/Quiz";
import Select from "react-select";
import VideoPlayer from "../../../../components/VideoPlayer";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import Shimmer from "react-shimmer-effect";
import EditorComponent from "../../../../components/Admin/Editor";

const BottomHeader: React.FC = () => {
	return (
		<div className="w-full bg-white flex flex-wrap justify-between">
			<div className="flex flex-wrap items-center px-6 py-4 ">
				<a>Kelola Pelatihan</a>
			</div>
		</div>
	);
};

const FormItemOpsi = ({ opsi }: { opsi: Array<string> }): JSX.Element => {
	const { selectedQuiz, jawabanBenerQuiz } =
		useKelolaKontenManajemenPelatihanContext().state;
	const [jawaban, setJawaban] = React.useState(`${jawabanBenerQuiz}`);
	const { setFormPilihanQuiz, setFormJawabanBenarQuiz, state } =
		useKelolaKontenManajemenPelatihanContext();

	let data: JSX.Element[] = [];
	opsi.forEach((pg, idx) => {
		const nilai = pg.toUpperCase();
		const formPilihan = `formPilihan${nilai}`;

		return data.push(
			<div className="mt-10">
				<div className="flex mb-2">
					<a>Jawaban {jawaban == pg ? "Benar" : "Salah"}</a>
					<Switch
						className="ml-2"
						height={20}
						onChange={() => {
							setJawaban(pg);
							setFormJawabanBenarQuiz(pg);
						}}
						checked={jawaban == pg ? true : false}
					/>
				</div>
				<EditorComponent
					description={
						selectedQuiz != null
							? selectedQuiz.JawabanQuiz[idx].pilihan
							: state[formPilihan]
					}
					setDescription={(a) => setFormPilihanQuiz(a, pg)}
					withKey={false}
				/>
			</div>
		);
	});
	return (
		<>
			{data.map((data) => (
				<div>{data}</div>
			))}
		</>
	);
};

const Section = ({ modul, idx }: { modul: ModulPelatihan; idx: number }) => {
	const {
		setShowDialogDelete,
		setShowDialogUpdate,
		setFormId,
		setSelectSection,
		setTypeDelete,
		setJenisFormSection,
	} = useKelolaKontenManajemenPelatihanContext();
	return (
		<div className="bg-gray-100 rounded-full flex justify-between mx-3 px-3 py-4 mt-3">
			<a className="text-xs font-semibold">
				Section {idx} :{" "}
				<span className="font-normal">{modul.judul_modul}</span>
			</a>
			<div className="dropdown relative">
				<a
					className="dropdown-toggle flex items-center hidden-arrow cursor-pointer"
					id="dropdownMenuButton2"
					role="button"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					<HiDotsVertical />
				</a>
				<ul
					className="
                            dropdown-menu
                            min-w-max
                            absolute
                            hidden
                            bg-white
                            text-base
                            z-50
                            float-left
                            py-2
                            list-none
                            text-left
                            rounded-lg
                            shadow-lg
                            mt-1
                            hidden
                            m-0
                            bg-clip-padding
                            border-none
                            left-auto
                            right-0
                        "
					aria-labelledby="dropdownMenuButton2"
				>
					<li>
						<a
							className="
                                dropdown-item
                                text-sm
                                py-2
                                px-4
                                font-normal
                                w-full
                                whitespace-nowrap
                                bg-transparent
                                text-gray-700
                                hover:bg-gray-100
                                 cursor-pointer
                            "
							onClick={() => {
								setShowDialogUpdate(true);
								setJenisFormSection("modul");
								setSelectSection(idx - 1, "modul", 0);
							}}
						>
							Ubah
						</a>
					</li>
					<li>
						<a
							className="
                                dropdown-item
                                text-sm
                                py-2
                                px-4
                                font-normal
                                w-full
                                whitespace-nowrap
                                bg-transparent
                                text-gray-700
                                hover:bg-gray-100
                                cursor-pointer
                            "
							onClick={() => {
								setShowDialogDelete(true);
								setFormId(modul.id);
								setTypeDelete("modul");
							}}
						>
							Hapus
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
};

const Widyaiswara = ({
	nama,
	path_foto,
}: {
	nama: string;
	path_foto: string;
}) => {
	return (
		<div className="bg-gray-100 rounded-full flex mx-3 px-3 py-2 mt-3">
			<img
				src={path_foto != "" ? path_foto : ImgUsers}
				className="rounded-full mb-1"
				style={{ width: "30px" }}
			/>
			<a className="ml-3 my-auto">{nama}</a>
		</div>
	);
};

const ItemSoal = ({
	idAccordion,
	nomor,
	soal,
	jawaban,
}: {
	idAccordion: string;
	nomor: number;
	soal: string;
	jawaban: Jawaban[];
}) => {
	let opsi = ["A", "B", "C", "D", "E"];

	return (
		<div className="accordion-item bg-white border border-gray-200 mt-3 rounded-lg">
			<button
				className="
            accordion-button
            collapsed
            relative
            flex
            items-center
            w-full
            py-4
            px-5
            text-sm text-gray-800 text-left
            border-0
            transition
            focus
            focus:outline-none
          "
				type="button"
				data-bs-toggle="collapse"
				data-bs-target={"#" + idAccordion}
				aria-expanded="true"
				aria-controls={"#" + idAccordion}
			>
				<a className="px-2 py-1 text-sm font-semibold border-2  border-sky-600">
					{nomor}
				</a>
				<p className="ml-4 mr-2">{parse(soal)}</p>
			</button>
			<div
				id={idAccordion}
				className="accordion-collapse collapse rounded-lg"
			>
				<div className="accordion-body rounded-lg">
					<div className="px-5 py-3">
						{jawaban.map((j, i) => {
							return (
								<ItemOpsi
									jawabanBenar={
										j.jawaban_benar == 1 ? true : false
									}
									opsi={opsi[i]}
									jawaban={j.pilihan}
								/>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

const ItemOpsi = ({
	jawabanBenar,
	opsi,
	jawaban,
}: {
	jawabanBenar: Boolean;
	opsi: string;
	jawaban: string;
}) => {
	return (
		<div
			className="flex relative px-3 py-3 mb-5 rounded-md"
			style={{ backgroundColor: jawabanBenar ? "#EFFFF9" : "#F3F3F3" }}
		>
			<a
				className="px-2 py-1 text-xs font-semibold rounded"
				style={{
					border: jawabanBenar
						? "1px solid #0DBD7F"
						: "1px solid #4F4F4F",
					color: jawabanBenar ? "#0DBD7F" : "#4F4F4F",
					height: "25px",
				}}
			>
				{opsi}
			</a>
			<a
				className="px-2 py-1 text-xs font-normal rounded ml-3"
				style={{
					border: jawabanBenar
						? "1px solid #0DBD7F"
						: "1px solid #4F4F4F",
					color: jawabanBenar ? "#0DBD7F" : "#4F4F4F",
					height: "25px",
				}}
			>
				Jawaban{jawabanBenar ? "Benar" : "Salah"}
			</a>
			<p
				className="px-2 py-1 text-xs font-normal rounded ml-3"
				style={{ color: jawabanBenar ? "#0DBD7F" : "#4F4F4F" }}
			>
				{parse(jawaban)}
			</p>
		</div>
	);
};

const ItemSoalQuiz = ({
	idAccordion,
	nomor,
	soal,
	jawaban,
}: {
	idAccordion: string;
	nomor: number;
	soal: string;
	jawaban: JawabanQuiz[];
}) => {
	const { setFormId, setShowDialogDeleteQuiz, setSelectedQuiz } =
		useKelolaKontenManajemenPelatihanContext();
	const { quiz } = useKelolaKontenManajemenPelatihanContext().state;

	let opsi = ["A", "B", "C", "D", "E"];

	return (
		<div className="accordion-item bg-white border border-gray-200 mt-3 rounded-lg">
			<button
				className="
            accordion-button
            collapsed
            relative
            flex
            items-center
            w-full
            py-4
            px-5
            text-sm text-gray-800 text-left
            border-0
            transition
            focus
            focus:outline-none
          "
				type="button"
				data-bs-toggle="collapse"
				data-bs-target={"#" + idAccordion}
				aria-expanded="true"
				aria-controls={"#" + idAccordion}
			>
				<a className="px-2 py-1 text-sm font-semibold border-2  border-sky-600">
					{nomor}
				</a>
				<p className="ml-4 mr-2">{parse(soal)}</p>
			</button>
			<div
				id={idAccordion}
				className="accordion-collapse collapse rounded-lg"
			>
				<div className="accordion-body rounded-lg">
					<div className="px-5 py-3 flex flex-row">
						Aksi :
						<button
							className="ml-2 px-2 py-1 text-xs text-white bg-green-600 rounded-lg"
							onClick={() => setSelectedQuiz(quiz[nomor - 1])}
						>
							Ubah Data
						</button>
						<button
							className="ml-2 px-2 py-1 text-xs text-white bg-red-600 rounded-lg"
							onClick={() => {
								setFormId(quiz[nomor - 1].id);
								setShowDialogDeleteQuiz(true);
							}}
						>
							Hapus Data
						</button>
					</div>
					<hr />
					<div className="px-5 py-3">
						{jawaban.map((j, i) => {
							return (
								<ItemOpsiQuiz
									jawabanBenar={
										j.jawaban_benar == 1 ? true : false
									}
									opsi={opsi[i]}
									jawaban={j.pilihan}
								/>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

const ItemOpsiQuiz = ({
	jawabanBenar,
	opsi,
	jawaban,
}: {
	jawabanBenar: Boolean;
	opsi: string;
	jawaban: string;
}) => {
	return (
		<div
			className="flex relative px-3 py-3 mb-5 rounded-md"
			style={{ backgroundColor: jawabanBenar ? "#EFFFF9" : "#F3F3F3" }}
		>
			<a
				className="px-2 py-1 text-xs font-semibold rounded"
				style={{
					border: jawabanBenar
						? "1px solid #0DBD7F"
						: "1px solid #4F4F4F",
					color: jawabanBenar ? "#0DBD7F" : "#4F4F4F",
					height: "25px",
				}}
			>
				{opsi}
			</a>
			<a
				className="px-2 py-1 text-xs font-normal rounded ml-3"
				style={{
					border: jawabanBenar
						? "1px solid #0DBD7F"
						: "1px solid #4F4F4F",
					color: jawabanBenar ? "#0DBD7F" : "#4F4F4F",
					height: "25px",
				}}
			>
				Jawaban{jawabanBenar ? "Benar" : "Salah"}
			</a>
			<p
				className="px-2 py-1 text-xs font-normal rounded ml-3"
				style={{ color: jawabanBenar ? "#0DBD7F" : "#4F4F4F" }}
			>
				{parse(jawaban)}
			</p>
		</div>
	);
};

const TabsMateri = () => {
	const { detailMateri } = useKelolaKontenManajemenPelatihanContext().state;

	return (
		<div>
			<a className="text-base">{detailMateri?.judul_materi}</a>
			<p className="text-sm mt-4 text-green-600">
				Jenis Pelatihan{" "}
				<span className="font-normal">
					: {detailMateri?.jenis_pelatihan}
				</span>
			</p>
			<p className="text-sm text-green-600">
				Jam Pelatihan{" "}
				<span className="font-normal">
					&nbsp;&nbsp;: {detailMateri?.jam_pelatihan} Jam Pelatihan
				</span>
			</p>
			<p className="text-justify text-sm text-gray-500 mt-3 mb-5 font-normal">
				{detailMateri != null
					? parse(detailMateri.uraian_deskripsi)
					: null}
			</p>
		</div>
	);
};

const TabsQuiz = () => {
	const { quiz } = useKelolaKontenManajemenPelatihanContext().state;
	return (
		<div className="overflow-y-scroll pr-3" style={{ height: "750px" }}>
			{quiz.map((q, i) => {
				return (
					<ItemSoalQuiz
						idAccordion={"Soal" + (i + 1)}
						nomor={i + 1}
						soal={q.pertanyaan}
						jawaban={q.JawabanQuiz}
					/>
				);
			})}
		</div>
	);
};

const TabsLatihan = () => {
	const {
		modulPelatihan,
		paketSoal,
		IndexPaketSoalIdLatihan,
		formLoading,
		formPassingGradeLatihan,
		formDurasiLatihan,
		formPaketSoalIdLatihan,
		formLimitPengulangan,
		optionsPaketSoal,
		formLimitSoal,
	} = useKelolaKontenManajemenPelatihanContext().state;

	const {
		setFormPassingGradeLatihan,
		setFormDurasiLatihan,
		setFormPaketSoalIdLatihan,
		getIndexPaketSoalIdLatihan,
		setShowModalDetailSoalLatihan,
		actionFormDialogLatihan,
		setFormLimitPengulangan,
		setFormLimitSoal,
	} = useKelolaKontenManajemenPelatihanContext();

	const paketSoalWithQuestions =
		paketSoal.filter((paket) => paket.soal.length > 0) || [];

	const selectedPaketSoal = paketSoalWithQuestions.find(
		(item) => item.id === formPaketSoalIdLatihan
	);
	const valuePaketSoal = selectedPaketSoal
		? { value: selectedPaketSoal.id, label: selectedPaketSoal.judul_soal }
		: null;

	return (
		<div>
			<div className="grid md:grid-cols-11 sm:grid-cols-3">
				<div className="col-span-2 md:col-span-2 sm:grid-cols-1">
					<label className="form-label inline-block py-1.5 text-gray-700 font-medium">
						Passing Grade
					</label>
				</div>
				<div className="col-span-4 md:col-span-4 sm:grid-cols-1">
					<input
						type="text"
						value={formPassingGradeLatihan}
						onChange={(e) =>
							setFormPassingGradeLatihan(e.target.value)
						}
						className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
					/>
				</div>
			</div>

			<div className="grid md:grid-cols-11 sm:grid-cols-3">
				<div className="col-span-2 md:col-span-2 sm:grid-cols-1">
					<label className="form-label inline-block py-1.5 text-gray-700 font-medium">
						Durasi Latihan
					</label>
				</div>
				<div className="col-span-4 md:col-span-4 sm:grid-cols-1">
					<input
						type="text"
						value={formDurasiLatihan}
						onChange={(e) => setFormDurasiLatihan(e.target.value)}
						className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
					/>
				</div>
			</div>

			<div className="grid md:grid-cols-11 sm:grid-cols-3">
				<div className="col-span-2 md:col-span-2 sm:grid-cols-1">
					<label className="form-label inline-block py-1.5 text-gray-700 font-medium">
						Limit Pengulangan
					</label>
				</div>
				<div className="col-span-4 md:col-span-4 sm:grid-cols-1">
					<input
						type="text"
						value={formLimitPengulangan}
						onChange={(e) =>
							setFormLimitPengulangan(e.target.value)
						}
						className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
					/>
				</div>
			</div>

			<div className="grid md:grid-cols-11 sm:grid-cols-3">
				<div className="col-span-2 md:col-span-2 sm:grid-cols-1">
					<label className="form-label inline-block py-1.5 text-gray-700 font-medium">
						Limit Soal
					</label>
				</div>
				<div className="col-span-4 md:col-span-4 sm:grid-cols-1">
					<input
						type="text"
						value={formLimitSoal}
						onChange={(e) => setFormLimitSoal(e.target.value)}
						className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
					/>
				</div>
			</div>

			<div className="grid md:grid-cols-11 sm:grid-cols-3">
				<div className="col-span-2 md:col-span-2 sm:grid-cols-1">
					<label className="form-label inline-block py-1.5 text-gray-700 font-medium">
						Paket Soal
					</label>
				</div>
				<div className="col-span-4 md:col-span-4 sm:grid-cols-1">
					<Select
						className="w-full"
						options={optionsPaketSoal}
						value={valuePaketSoal}
						onChange={(val: any) => {
							setFormPaketSoalIdLatihan(val.value);
							getIndexPaketSoalIdLatihan(val.value, paketSoal);
						}}
						placeholder="Pilih Soal"
					/>
				</div>
				<div className="col-span-4 md:col-span-4 sm:grid-cols-1">
					<button
						className="px-6 py-2.5 bg-green-400 text-white font-semibold text-xs rounded shadow-md hover:bg-green-700 mt-1 ml-4"
						onClick={() => setShowModalDetailSoalLatihan(true)}
					>
						Detail Soal
					</button>
				</div>
			</div>

			<div className="grid md:grid-cols-11 sm:grid-cols-3 mt-4">
				<div className="col-span-2 md:col-span-2 sm:grid-cols-1">
					<label className="form-label inline-block py-1.5 text-gray-700 font-medium">
						Jumlah Soal
					</label>
				</div>
				<div className="col-span-4 md:col-span-4 sm:grid-cols-1">
					<label className="form-label inline-block py-1.5 text-gray-700 font-medium">
						{
							paketSoal.find(
								(item) => item.id == formPaketSoalIdLatihan
							)?.soal.length
						}{" "}
						Soal
					</label>
				</div>
			</div>

			<div className="grid md:grid-cols-11 sm:grid-cols-3 mt-4">
				<div className="col-span-2 md:col-span-2 sm:grid-cols-1">
					<label className="form-label inline-block py-1.5 text-gray-700 font-medium">
						Kategori Soal
					</label>
				</div>
				<div className="col-span-4 md:col-span-4 sm:grid-cols-1">
					<label className="form-label inline-block py-1.5 text-gray-700 font-medium">
						{
							paketSoal[IndexPaketSoalIdLatihan]
								.kategori_paket_soal.kategori_paket_soal
						}
					</label>
				</div>
			</div>

			<div className="grid md:grid-cols-11 sm:grid-cols-3 mt-4">
				<div className="col-span-2 md:col-span-2 sm:grid-cols-1">
					<label className="form-label inline-block py-1.5 text-gray-700 font-medium">
						Jenis Soal
					</label>
				</div>
				<div className="col-span-4 md:col-span-4 sm:grid-cols-1">
					<label className="form-label inline-block py-1.5 text-gray-700 font-medium">
						{paketSoal[IndexPaketSoalIdLatihan].jenis_soal}
					</label>
				</div>
			</div>

			<div className="grid md:grid-cols-11 sm:grid-cols-3 mt-4">
				<div className="col-span-2 md:col-span-2 sm:grid-cols-1">
					<label className="form-label inline-block py-1.5 text-gray-700 font-medium">
						Petunjuk Soal
					</label>
				</div>
				<div
					className="col-span-4 md:col-span-4 sm:grid-cols-1"
					style={{ marginTop: "5px" }}
				>
					{parse(paketSoal[IndexPaketSoalIdLatihan].petunjuk_soal)}
				</div>
			</div>

			<button
				type="button"
				className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mt-4"
				onClick={() => {
					actionFormDialogLatihan("Ubah");
				}}
			>
				<If condition={formLoading}>
					<Then>
						<ReactLoading
							type={"bars"}
							color={"white"}
							height={15}
							width={20}
						/>
					</Then>
					<Else>Simpan Perubahan</Else>
				</If>
			</button>
		</div>
	);
};

const Konten: React.FC = () => {
	const {
		getDetailPelatihan,
		getModulPelatihan,
		getMateri,
		getPaketSoal,
		setShowDialog,
		setShowDialogDelete,
		setFormId,
		setTypeDelete,
		setShowDialogUpdate,
		setSelectSection,
		setJenisFormSection,
		selectSubModul,
		setShowDialogQuiz,
		setTypeDialogFormQuiz,
		clearForm,
		clickAdaLatihanMateri,
		setFormSubModulProjectPanduanPengerjaan,
		setFormSubModulProjectFilePanduan,
		actionFinalProject,
		setIndexPathMateri,
		goToPenilaianStep,
	} = useKelolaKontenManajemenPelatihanContext();

	const {
		modulPelatihan,
		isLoading,
		selectSubModulType,
		selectModulIdx,
		selectSubModulIdx,
		detailPaketSoal,
		detailMateri,
		checkSectionMateriAdaLatihan,
		quiz,
		detailPelatihan,
		formSubModulProjectPanduanPengerjaan,
		subModulProjectFilePanduan,
		checkSubModulHaveFinalProject,
		pathMateri,
		indexPathMateri,
		isLoadingPaketSoal,
	} = useKelolaKontenManajemenPelatihanContext().state;

	useEffect(() => {
		getDetailPelatihan(window.location.pathname.split("/", 5)[4]).then(
			(data) => {
				getModulPelatihan(data.id);
				getMateri(data.jenis_pelatihan_id);
				getPaketSoal();
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let judulSection = "";

	if (
		modulPelatihan.length > 0 &&
		selectSubModulType != "" &&
		selectSubModulType != "sub_modul_materi"
	) {
		if (selectSubModulType == "sub_modul_ujian_project") {
			judulSection =
				modulPelatihan[Number(selectModulIdx)].subModulPelatihan[
					Number(selectSubModulIdx)
				].judul + " : Project";
		} else {
			judulSection =
				modulPelatihan[Number(selectModulIdx)].subModulPelatihan[
					Number(selectSubModulIdx)
				].judul +
				" : Detail Ujian" +
				` (${detailPaketSoal?.soal.length} Soal)`;
		}
	}

	let optionsPathMateri;

	if (pathMateri != null && pathMateri.length > 0) {
		optionsPathMateri = pathMateri.map((val, idx) => ({
			value: idx,
			label: val.jenis_materi.toUpperCase(),
		}));
	} else {
		optionsPathMateri = [];
	}

	//  video js options
	let urlVideo;

	if (pathMateri != null && pathMateri.length > 0) {
		urlVideo = `https://www.youtube.com/watch?v=${pathMateri[indexPathMateri].path_materi}`;
	} else {
		urlVideo = null;
	}

	const videoJsOptions = {
		controls: true,
		responsive: true,
		fluid: true,
		aspectRatio: "16:9",
		controlBar: {
			children: [
				"playToggle",
				"currentTimeDisplay",
				"durationDisplay",
				"volumePanel",
				"fullscreenToggle",
				"progressControl",
				"controlBar",
			],
		},
		userActions: {
			hotkeys: false,
			click: false,
		},
		techOrder: ["youtube"],
		sources: [
			{
				src: urlVideo,
				type: "video/youtube",
			},
		],
		youtube: {
			controls: 0,
			rel: 0,
			modestbranding: 1,
			disablekb: 1,
			fs: 0,
			enablejsapi: 1,
		},
	};

	return (
		<div className="bg-white rounded-lg  mt-5">
			<div
				className="grid md:grid-cols-11 sm:grid-cols-3"
				style={{ borderBottom: "1px solid #E0E0E0", width: "100%" }}
			>
				<div
					className="col-span-3 md:col-span-3 sm:grid-cols-1 "
					style={{ borderRight: "1px solid #E0E0E0", height: "100%" }}
				>
					<p className="text-center px-5 py-5">
						Total JP :{" "}
						{detailPelatihan != null
							? detailPelatihan.jp + " Jam Pelatihan"
							: ""}{" "}
					</p>
					<div
						className="py-5"
						style={{
							borderTop: "1px solid #E0E0E0",
							width: "100%",
						}}
					>
						<div className="flex justify-between">
							<a className="font-semibold ml-3">Section </a>
							<button
								type="button"
								className="mr-3 flex px-1 py-1 bg-green-600 text-white font-semibold text-xs leading-tight rounded-full shadow-md hover:bg-green-700"
								onClick={() => setShowDialog(true)}
							>
								<AiOutlinePlus />
							</button>
						</div>
						{isLoadingPaketSoal ? (
							<div className="mx-4">
								{Array.from({ length: 3 }).map((_, idx) => (
									<Shimmer>
										<div className="container mt-4 p-5"></div>
									</Shimmer>
								))}
							</div>
						) : (
							<div
								className="overflow-y-auto"
								style={{ height: "550px" }}
							>
								<If condition={modulPelatihan.length > 0}>
									<Then>
										{modulPelatihan.map((m, idx) => {
											let SubSection: JSX.Element[] = [];
											m.subModulPelatihan.map(
												(sm, idxSm) => {
													return SubSection.push(
														<div className="bg-gray-100 rounded-full flex justify-between mx-3 px-3 py-4 mt-3">
															<a
																className="text-xs font-semibold flex cursor-pointer"
																style={{
																	color:
																		selectSubModulIdx !=
																			"" &&
																		modulPelatihan[
																			Number(
																				selectModulIdx
																			)
																		]
																			.subModulPelatihan[
																			Number(
																				selectSubModulIdx
																			)
																		].id ==
																			modulPelatihan[
																				Number(
																					idx
																				)
																			]
																				.subModulPelatihan[
																				Number(
																					idxSm
																				)
																			]
																				.id &&
																		selectSubModulType !=
																			""
																			? "#219653"
																			: "#000000",
																}}
																onClick={() => {
																	if (
																		sm.jenis_sub_modul ==
																		"materi"
																	) {
																		selectSubModul(
																			idx,
																			idxSm,
																			"sub_modul_materi"
																		);
																		setJenisFormSection(
																			"sub_modul_materi"
																		);
																		setSelectSection(
																			idx,
																			"submodul",
																			idxSm
																		);
																	} else {
																		if (
																			sm.jenis_ujian ==
																			"project"
																		) {
																			selectSubModul(
																				idx,
																				idxSm,
																				"sub_modul_ujian_project"
																			);
																		} else {
																			selectSubModul(
																				idx,
																				idxSm,
																				"sub_modul_ujian_ujian"
																			);
																		}
																	}
																}}
															>
																<ImFilePlay
																	className="mr-2"
																	style={{
																		marginTop:
																			"1px",
																	}}
																/>
																{sm.judul}
															</a>
															<div className="dropdown relative">
																<a
																	className="dropdown-toggle flex items-center hidden-arrow cursor-pointer"
																	id="dropdownMenuButton2"
																	role="button"
																	data-bs-toggle="dropdown"
																	aria-expanded="false"
																>
																	<HiDotsVertical />
																</a>
																<ul
																	className="
                                                                    dropdown-menu
                                                                    min-w-max
                                                                    absolute
                                                                    hidden
                                                                    bg-white
                                                                    text-base
                                                                    z-50
                                                                    float-left
                                                                    py-2
                                                                    list-none
                                                                    text-left
                                                                    rounded-lg
                                                                    shadow-lg
                                                                    mt-1
                                                                    hidden
                                                                    m-0
                                                                    bg-clip-padding
                                                                    border-none
                                                                    left-auto
                                                                    right-0
                                                                "
																	aria-labelledby="dropdownMenuButton2"
																>
																	<li>
																		<a
																			className="
                                                                        dropdown-item
                                                                        text-sm
                                                                        py-2
                                                                        px-4
                                                                        font-normal
                                                                        w-full
                                                                        whitespace-nowrap
                                                                        bg-transparent
                                                                        text-gray-700
                                                                        hover:bg-gray-100
                                                                        cursor-pointer
                                                                    "
																			onClick={() => {
																				setShowDialogUpdate(
																					true
																				);
																				if (
																					sm.jenis_sub_modul ==
																					"materi"
																				) {
																					setJenisFormSection(
																						"sub_modul_materi"
																					);
																				} else {
																					if (
																						sm.jenis_ujian ==
																						"project"
																					) {
																						setJenisFormSection(
																							"sub_modul_ujian_project"
																						);
																					} else {
																						setJenisFormSection(
																							"sub_modul_ujian_ujian"
																						);
																					}
																				}
																				setSelectSection(
																					idx,
																					"submodul",
																					idxSm
																				);
																			}}
																		>
																			Ubah
																		</a>
																	</li>
																	<li>
																		<a
																			className="
                                                                        dropdown-item
                                                                        text-sm
                                                                        py-2
                                                                        px-4
                                                                        font-normal
                                                                        w-full
                                                                        whitespace-nowrap
                                                                        bg-transparent
                                                                        text-gray-700
                                                                        hover:bg-gray-100
                                                                        cursor-pointer
                                                                    "
																			onClick={() => {
																				setShowDialogDelete(
																					true
																				);
																				setFormId(
																					sm.id
																				);
																				setTypeDelete(
																					"submodul"
																				);
																			}}
																		>
																			Hapus
																		</a>
																	</li>
																</ul>
															</div>
														</div>
													);
												}
											);

											return (
												<>
													<Section
														modul={m}
														idx={idx + 1}
													/>
													{SubSection}
												</>
											);
										})}
									</Then>
								</If>
							</div>
						)}
					</div>
					<If condition={detailMateri != null}>
						<div
							className="py-5 overflow-y-auto"
							style={{
								borderTop: "1px solid #E0E0E0",
								width: "100%",
								height: "300px",
							}}
						>
							<a className="font-semibold ml-3">Widyaiswara</a>
							{detailMateri?.widyaiswara_assigned.map((w) => {
								return (
									<>
										<Widyaiswara
											nama={w.name}
											path_foto={w.path_foto}
										/>
									</>
								);
							})}
						</div>
					</If>
				</div>

				<div className="col-span-8 md:col-span-8 sm:grid-cols-1">
					<div className="grid md:grid-cols-2 sm:grid-cols-1">
						<If
							condition={selectSubModulType == "sub_modul_materi"}
						>
							<Then>
								<div className="col-span-1 md:col-span-1 sm:grid-cols-1 px-2">
									<button
										type="button"
										className="w-full mr-5 flex justify-center  py-2.5  border border-green-500 text-green-500 font-semibold text-xs text-center rounded-lg shadow-md hover:bg-green-700 mb-3 mt-3"
										style={{ backgroundColor: "#E3FFF5" }}
										onClick={() => {
											clearForm();
											setShowDialogQuiz(true);
											setTypeDialogFormQuiz("Tambah");
										}}
									>
										Insert Quiz
										<AiOutlinePlusCircle
											className="ml-2"
											style={{ marginTop: "2px" }}
										/>
									</button>
								</div>
								<div className="col-span-1 md:col-span-1 sm:grid-cols-1 px-2">
									<div
										className="w-full mr-5 flex justify-center py-2.5  border border-yellow-500 text-yellow-500 font-semibold text-xs text-center rounded-lg shadow-md hover:bg-green-700 mb-3 mt-3"
										style={{ backgroundColor: "#FFF6EA" }}
									>
										Ada Latihan ?
										<input
											type="checkbox"
											className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-gren-600 checked:border-gren-600 focus:outline-none transition duration-200 ml-2  bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
											checked={
												checkSectionMateriAdaLatihan
											}
											onClick={() =>
												clickAdaLatihanMateri(
													!checkSectionMateriAdaLatihan
												)
											}
										/>
									</div>
								</div>
							</Then>
							<Else>
								<a className="px-5 py-5">{judulSection}</a>
							</Else>
						</If>
					</div>
					<div
						className="py-5 px-5"
						style={{
							borderTop: "1px solid #E0E0E0",
							width: "100%",
						}}
					>
						<If condition={selectSubModulType == ""}>
							<h3
								className="text-center text-2xl"
								style={{ marginTop: "50%" }}
							>
								Belum Memilih Sub Section
							</h3>
						</If>

						<If
							condition={
								selectSubModulType == "sub_modul_ujian_ujian"
							}
						>
							<Then>
								<div
									className="overflow-y-scroll pr-3"
									style={{ height: "750px" }}
								>
									{detailPaketSoal?.soal.map((s, i) => {
										return (
											<ItemSoal
												idAccordion={"Soal" + (i + 1)}
												nomor={i + 1}
												soal={s.soal}
												jawaban={s.jawaban}
											/>
										);
									})}
								</div>
							</Then>
						</If>

						<If
							condition={
								selectSubModulType == "sub_modul_ujian_project"
							}
						>
							<Then>
								<label className="form-label inline-block py-1.5 text-gray-700 font-medium">
									Panduan Pengerjaan
								</label>
								<If
									condition={
										checkSubModulHaveFinalProject == 1
									}
								>
									<Then>
										<EditorComponent
											description={
												formSubModulProjectPanduanPengerjaan
											}
											setDescription={(val) =>
												setFormSubModulProjectPanduanPengerjaan(
													val
												)
											}
											withKey={false}
										/>
									</Then>
									<Else>
										<EditorComponent
											description={
												formSubModulProjectPanduanPengerjaan
											}
											setDescription={(val) =>
												setFormSubModulProjectPanduanPengerjaan(
													val
												)
											}
											withKey={false}
										/>
									</Else>
								</If>

								<div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-5">
									<div className="col-span-3 md:col-span-3 sm:grid-cols-1">
										<label className="form-label inline-block py-1.5 text-gray-700 font-medium">
											Upload File Panduan
										</label>
									</div>
									<div className="col-span-5 md:col-span-5 sm:grid-cols-2">
										<input
											type="file"
											className="form-control
                                  block
                                  w-full
                                  px-3
                                  py-1.5
                                  text-base
                                  font-normal
                                  text-gray-700
                                  bg-white bg-clip-padding
                                  border border-solid border-gray-300
                                  rounded
                                  transition
                                  ease-in-out
                                  m-0
                                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
											onChange={(e) =>
												setFormSubModulProjectFilePanduan(
													e.target.files
												)
											}
										/>
									</div>
								</div>

								<If
									condition={
										subModulProjectFilePanduan != null
									}
								>
									<Then>
										<a
											href={subModulProjectFilePanduan?.toString()}
										>
											Lihat File
										</a>
									</Then>
								</If>

								<button
									type="button"
									className="mr-5 flex px-6 py-2.5 bg-green-600 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-green-700 mb-3 mt-3"
									onClick={() => actionFinalProject()}
								>
									<If condition={isLoading}>
										<Then>
											<ReactLoading
												type={"bars"}
												color={"white"}
												height={15}
												width={20}
											/>
										</Then>
										<Else>Simpan</Else>
									</If>
									<AiOutlineSave
										className="ml-2"
										style={{ marginTop: "2px" }}
									/>
								</button>
							</Then>
						</If>

						<If
							condition={selectSubModulType == "sub_modul_materi"}
						>
							<Then>
								{pathMateri != null && pathMateri.length > 0 ? (
									<>
										<If
											condition={
												pathMateri[indexPathMateri]
													.jenis_materi == "video"
											}
										>
											<Then>
												<VideoPlayer
													options={videoJsOptions}
												/>
											</Then>
										</If>
										<If
											condition={
												pathMateri[indexPathMateri]
													.jenis_materi == "audio"
											}
										>
											<Then>
												<ReactAudioPlayer
													src={
														pathMateri[
															indexPathMateri
														].path_materi
													}
													autoPlay
													controls
													style={{
														borderRadius: 10 + "px",
														width: "100%",
														color: "#111111",
													}}
												/>
											</Then>
										</If>
										<If
											condition={
												pathMateri[indexPathMateri]
													.jenis_materi == "ppt"
											}
										>
											<Then>
												<iframe
													src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
														pathMateri[
															indexPathMateri
														].path_materi
													)}`}
													width="100%"
													height="600px"
													style={{
														borderRadius: "6px",
													}}
												/>
											</Then>
										</If>
										<If
											condition={
												pathMateri[indexPathMateri]
													.jenis_materi == "pdf"
											}
										>
											<Then>
												<iframe
													src={
														pathMateri[
															indexPathMateri
														].path_materi
													}
													width="100%"
													height="600px"
													style={{
														borderRadius: "6px",
													}}
												/>
											</Then>
										</If>
									</>
								) : null}
								<div className="flex flex-row justify-between">
									<ul
										className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0  mt-4"
										id="tabs-tab3"
										role="tablist"
									>
										<li
											className="nav-item"
											role="presentation"
										>
											<a
												href="#tabs-materi"
												className="
                                      nav-link
                                      w-full
                                      block
                                      font-medium
                                      text-xs
                                      leading-tight
                                      uppercase
                                      border-x-0 border-t-0 border-b-2 border-gray
                                      px-6
                                      py-3
                                      my-2
                                      hover:border-transparent hover:bg-gray-100
                                      focus:border-transparent
                                      active
                                      "
												data-bs-toggle="pill"
												data-bs-target="#tabs-materi"
												role="tab"
												aria-controls="tabs-materi"
												aria-selected="true"
											>
												Materi
											</a>
										</li>
										<If condition={quiz.length > 0}>
											<Then>
												<li
													className="nav-item"
													role="presentation"
												>
													<a
														href="#tabs-quiz"
														className="
                                      nav-link
                                      w-full
                                      block
                                      font-medium
                                      text-xs
                                      leading-tight
                                      uppercase
                                      border-x-0 border-t-0 border-b-2 border-gray
                                      px-6
                                      py-3
                                      my-2
                                      hover:border-transparent hover:bg-gray-100
                                      focus:border-transparent
                                      "
														data-bs-toggle="pill"
														data-bs-target="#tabs-quiz"
														role="tab"
														aria-controls="tabs-quiz"
														aria-selected="false"
													>
														Quiz
													</a>
												</li>
											</Then>
										</If>
										<If
											condition={
												checkSectionMateriAdaLatihan
											}
										>
											<Then>
												<li
													className="nav-item"
													role="presentation"
												>
													<a
														href="#tabs-latihan"
														className="
                                          nav-link
                                          w-full
                                          block
                                          font-medium
                                          text-xs
                                          leading-tight
                                          uppercase
                                          border-x-0 border-t-0 border-b-2 border-gray
                                          px-6
                                          py-3
                                          my-2
                                          hover:border-transparent hover:bg-gray-100
                                          focus:border-transparent
                                          "
														data-bs-toggle="pill"
														data-bs-target="#tabs-latihan"
														role="tab"
														aria-controls="tabs-latihan"
														aria-selected="false"
													>
														Latihan
													</a>
												</li>
											</Then>
										</If>
									</ul>

									<div className="mt-7 grid grid-cols-1 gap-5">
										{/* <Select
                      className="w-full"
                      options={optionsMateri}
                      placeholder="Pilih Materi"
                      defaultValue={optionsMateri[0]}
                      onChange={(val: any) => getDetailMateri(val.value)}
                    /> */}

										{optionsPathMateri.length > 0 ? (
											<Select
												className="w-full"
												options={optionsPathMateri}
												placeholder="Pilih Jenis Materi"
												defaultValue={
													optionsPathMateri[0]
												}
												onChange={(val: any) =>
													setIndexPathMateri(
														val.value
													)
												}
											/>
										) : null}
									</div>
								</div>

								<div
									className="tab-content "
									id="tabs-tabContent3"
								>
									<div
										className="tab-pane fade show active"
										id="tabs-materi"
										role="tabpanel"
									>
										<TabsMateri />
									</div>
									<If condition={quiz.length > 0}>
										<Then>
											<div
												className="tab-pane fade"
												id="tabs-quiz"
												role="tabpanel"
											>
												<TabsQuiz />
											</div>
										</Then>
									</If>
									<If
										condition={
											checkSectionMateriAdaLatihan &&
											modulPelatihan.length > 0
										}
									>
										<Then>
											<div
												className="tab-pane fade"
												id="tabs-latihan"
												role="tabpanel"
											>
												<TabsLatihan />
											</div>
										</Then>
									</If>
								</div>
							</Then>
						</If>
					</div>
				</div>
			</div>

			<div className="flex justify-end">
				<a
					href={`/admin/ubah/manajemen_pelatihan/${
						window.location.pathname.split("/", 5)[4]
					}`}
					className="px-6 py-2.5 border border-green-400 text-green-400 font-semibold text-xs rounded shadow-md hover:bg-green-700 mb-3 mt-3 mr-2"
				>
					Sebelumnya
				</a>
				<button
					onClick={() => goToPenilaianStep()}
					className="px-6 py-2.5 bg-green-400 text-white font-semibold text-xs rounded shadow-md hover:bg-green-700 mb-3 mt-3 mr-4"
				>
					Selanjutnya
				</button>
			</div>
		</div>
	);
};

const ModalCreateData: React.FC = () => {
	const {
		formLoading,
		showDialog,
		jenisFormSection,
		modulPelatihan,
		materi,
		detailPelatihan,
		optionsPaketSoal,
		pelatihanId,
	} = useKelolaKontenManajemenPelatihanContext().state;
	const {
		actionFormDialog,
		setShowDialog,
		setJenisFormSection,
		setFormJudul,
		setFormJenisSubModul,
		setFormMateriId,
		setFormPaketSoalId,
		setFormModulPelatihanId,
		setFormSubModulDurasiLatihan,
		setFormSubModulPassingGrade,
		setFormSequence,
		setFormLimitSoal,
	} = useKelolaKontenManajemenPelatihanContext();

	return (
		<>
			{showDialog ? (
				<>
					{/* Background Modal */}
					<div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

					{/* Modal */}
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
							<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
								{/* Modal content */}
								<div className="flex items-start justify-between p-4 rounded-t">
									<h3 className="text-xl font-semibold text-gray-900">
										Tambah Section
									</h3>
									<button
										type="button"
										className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
										onClick={() => setShowDialog(false)}
										style={{ color: "white" }}
									>
										<svg
											className="w-5 h-5"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									</button>
								</div>
								<div className="p-6 space-y-6 -mt-2 overflow-y-auto h-96">
									<div className="form-group grid grid-cols-6">
										<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
											Jenis Section
										</label>
										<div className="flex flex-wrap mt-1 col-span-4">
											<div className="form-check">
												<input
													className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
													type="checkbox"
													checked={
														jenisFormSection ==
														"modul"
															? true
															: false
													}
													onClick={() => {
														setJenisFormSection(
															"modul"
														);
														setFormJenisSubModul(
															""
														);
													}}
												/>
												<label className="form-check-label inline-block text-gray-800">
													Section
												</label>
											</div>
											{!detailPelatihan?.event_id && (
												<div className="form-check ml-3">
													<input
														className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
														type="checkbox"
														checked={
															jenisFormSection ==
															"sub_modul_materi"
																? true
																: false
														}
														onClick={() => {
															setJenisFormSection(
																"sub_modul_materi"
															);
															setFormJenisSubModul(
																""
															);
														}}
													/>
													<label className="form-check-label inline-block text-gray-800">
														Materi
													</label>
												</div>
											)}
											<div className="form-check ml-3">
												<input
													className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
													type="checkbox"
													checked={
														jenisFormSection ==
															"sub_modul_ujian_project" ||
														jenisFormSection ==
															"sub_modul_ujian_ujian"
															? true
															: false
													}
													onClick={() => {
														setFormJenisSubModul(
															"project"
														);
														setJenisFormSection(
															"sub_modul_ujian_project"
														);
													}}
												/>
												<label className="form-check-label inline-block text-gray-800">
													Ujian
												</label>
											</div>
										</div>
										{jenisFormSection ===
											"sub_modul_ujian_project" && (
											<>
												<div className="col-span-2"></div>
												<div className="col-span-4">
													<div className="text-red-500 text-xs">
														ujian hanya bisa
														bitambahkan diakhir
														section pelatihan
													</div>
												</div>
											</>
										)}
									</div>
									<If condition={jenisFormSection != "modul"}>
										<Then>
											<div className="form-group grid grid-cols-6">
												<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
													Pilih Section
												</label>
												<select
													className="form-control
                          col-span-4
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
													onChange={(e) =>
														setFormModulPelatihanId(
															e.target.value
														)
													}
												>
													<option value={0}>
														Pilih Section
													</option>
													<If
														condition={
															modulPelatihan.length >
															0
														}
													>
														<Then>
															{modulPelatihan.map(
																(m, idx) => {
																	return (
																		<option
																			value={
																				m.id
																			}
																		>
																			Section{" "}
																			{idx +
																				1}{" "}
																			:{" "}
																			{
																				m.judul_modul
																			}
																		</option>
																	);
																}
															)}
														</Then>
													</If>
												</select>
											</div>
										</Then>
									</If>

									<If
										condition={
											jenisFormSection ==
											"sub_modul_materi"
										}
									>
										<Then>
											<div className="form-group grid grid-cols-6">
												<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
													Pilih Materi
												</label>
												<div className="col-span-4">
													<select
														className="form-control
                          col-span-4
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
														onChange={(e) =>
															setFormMateriId(
																e.target.value
															)
														}
													>
														<option value={0}>
															Pilih Materi
														</option>
														<If
															condition={
																materi.length >
																0
															}
														>
															<Then>
																{materi.map(
																	(
																		m,
																		idx
																	) => {
																		return (
																			<option
																				value={
																					m.id
																				}
																			>
																				{
																					m.judul_materi
																				}
																			</option>
																		);
																	}
																)}
															</Then>
														</If>
													</select>
													{/* <MultiSelect
                            options={optionsMateri}
                            onChange={setFormMateriId}
                            labelledBy="Select"
                            value={formMateriId}
                          /> */}
												</div>
											</div>
										</Then>
									</If>

									<If
										condition={
											jenisFormSection ==
												"sub_modul_ujian_project" ||
											jenisFormSection ==
												"sub_modul_ujian_ujian"
										}
									>
										<Then>
											<div className="form-group grid grid-cols-6">
												<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
													Jenis Section
												</label>
												<div className="flex flex-wrap mt-1 col-span-4">
													<div className="form-check">
														<input
															className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
															type="checkbox"
															checked={
																jenisFormSection ==
																"sub_modul_ujian_project"
																	? true
																	: false
															}
															onClick={() => {
																setJenisFormSection(
																	"sub_modul_ujian_project"
																);
																setFormJenisSubModul(
																	"project"
																);
															}}
														/>
														<label className="form-check-label inline-block text-gray-800">
															Project
														</label>
													</div>
													<div className="form-check ml-3">
														<input
															className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
															type="checkbox"
															checked={
																jenisFormSection ==
																"sub_modul_ujian_ujian"
																	? true
																	: false
															}
															onClick={() => {
																setJenisFormSection(
																	"sub_modul_ujian_ujian"
																);
																setFormJenisSubModul(
																	"ujian"
																);
															}}
														/>
														<label className="form-check-label inline-block text-gray-800">
															Ujian
														</label>
													</div>
												</div>
											</div>
										</Then>
									</If>

									<div className="form-group grid grid-cols-6 mt-2">
										<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
											Judul
										</label>
										<textarea
											rows={5}
											className="form-control
                                    col-span-4
                                    w-full
                                    px-3
                                    py-1.5
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
											onChange={(e) =>
												setFormJudul(e.target.value)
											}
											placeholder="Masukkan Judul"
										/>
									</div>

									<div className="form-group grid grid-cols-6 mt-2">
										<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
											Urutan
										</label>
										<input
											type="number"
											className="form-control
                                    col-span-4
                                    w-full
                                    px-3
                                    py-1.5
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
											onChange={(e) =>
												setFormSequence(e.target.value)
											}
											placeholder="Masukkan Urutan"
										/>
									</div>

									<If
										condition={
											jenisFormSection ==
											"sub_modul_ujian_ujian"
										}
									>
										<Then>
											<div className="form-group grid grid-cols-6">
												<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
													Pilih Soal
												</label>
												<div className="col-span-4">
													<Select
														className="w-full"
														options={
															optionsPaketSoal
														}
														onChange={(val: any) =>
															setFormPaketSoalId(
																val.value
															)
														}
														placeholder="Pilih Soal"
													/>
												</div>
											</div>

											<div className="form-group grid grid-cols-6">
												<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
													Durasi Ujian
												</label>
												<input
													type="number"
													min={1}
													className="form-control
                          col-span-4
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
													onChange={(e) =>
														setFormSubModulDurasiLatihan(
															e.target.value
														)
													}
													placeholder="Masukkan Durasi (Menit)"
												/>
											</div>

											<div className="form-group grid grid-cols-6">
												<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
													Passing Grade
												</label>
												<input
													type="number"
													min={1}
													max={100}
													className="form-control
                          col-span-4
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
													onChange={(e) =>
														setFormSubModulPassingGrade(
															e.target.value
														)
													}
													placeholder="Masukkan Passing Grade"
												/>
											</div>

											<div className="form-group grid grid-cols-6">
												<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
													Limit Soal
												</label>
												<input
													type="number"
													min={1}
													max={100}
													className="form-control
                          col-span-4
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
													onChange={(e) =>
														setFormLimitSoal(
															e.target.value
														)
													}
													placeholder="Masukkan Limit Soal"
												/>
											</div>
										</Then>
									</If>
								</div>
								<div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
										onClick={() => setShowDialog(false)}
									>
										Batal
									</button>
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
										onClick={() =>
											actionFormDialog(pelatihanId)
										}
									>
										<If condition={formLoading}>
											<Then>
												<ReactLoading
													type={"bars"}
													color={"white"}
													height={15}
													width={20}
												/>
											</Then>
											<Else>Simpan</Else>
										</If>
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

const ModalUpdateData: React.FC = () => {
	const {
		formLoading,
		showDialogUpdate,
		jenisFormSection,
		modulPelatihan,
		materi,
		paketSoal,
		formJudul,
		formModulPelatihanId,
		formMateriId,
		formPaketSoalId,
		formSubModulDurasiLatihan,
		formSubModulPassingGrade,
		formSequence,
		formLimitSoal,
	} = useKelolaKontenManajemenPelatihanContext().state;

	const {
		actionUpdate,
		setShowDialogUpdate,
		setJenisFormSection,
		setFormJudul,
		setFormJenisSubModul,
		setFormMateriId,
		setFormPaketSoalId,
		setFormModulPelatihanId,
		setFormSubModulDurasiLatihan,
		setFormSubModulPassingGrade,
		setFormSequence,
		setFormLimitSoal,
	} = useKelolaKontenManajemenPelatihanContext();

	let optionsPaketSoal;
	let valuePaketSoal;

	if (paketSoal.length > 0) {
		optionsPaketSoal = paketSoal.map((item) => ({
			value: item.id,
			label: item.judul_soal,
		}));
		if (formPaketSoalId != 0) {
			paketSoal.map((item) => {
				if (item.id == formPaketSoalId) {
					valuePaketSoal = { value: item.id, label: item.judul_soal };
				}

				return valuePaketSoal;
			});
		}
	} else {
		optionsPaketSoal = [];
	}

	return (
		<>
			{showDialogUpdate ? (
				<>
					{/* Background Modal */}
					<div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

					{/* Modal */}
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
							<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
								{/* Modal content */}
								<div className="flex items-start justify-between p-4 rounded-t">
									<h3 className="text-xl font-semibold text-gray-900">
										Ubah Section
									</h3>
									<button
										type="button"
										className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
										onClick={() =>
											setShowDialogUpdate(false)
										}
										style={{ color: "white" }}
									>
										<svg
											className="w-5 h-5"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									</button>
								</div>
								<div className="p-6 space-y-6 -mt-2 overflow-y-auto h-96">
									<If condition={jenisFormSection != "modul"}>
										<Then>
											<div className="form-group grid grid-cols-6">
												<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
													Pilih Section
												</label>
												<select
													className="form-control
                          col-span-4
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
													value={formModulPelatihanId}
													onChange={(e) =>
														setFormModulPelatihanId(
															e.target.value
														)
													}
												>
													<option value={0}>
														Pilih Section
													</option>
													<If
														condition={
															modulPelatihan.length >
															0
														}
													>
														<Then>
															{modulPelatihan.map(
																(m, idx) => {
																	return (
																		<option
																			value={
																				m.id
																			}
																		>
																			Section{" "}
																			{idx +
																				1}{" "}
																			:{" "}
																			{
																				m.judul_modul
																			}
																		</option>
																	);
																}
															)}
														</Then>
													</If>
												</select>
											</div>
										</Then>
									</If>

									<If
										condition={
											jenisFormSection ==
											"sub_modul_materi"
										}
									>
										<Then>
											<div className="form-group grid grid-cols-6">
												<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
													Pilih Materi
												</label>
												<div className="col-span-4">
													<select
														className="form-control
                          col-span-4
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
														value={formMateriId[0]}
														onChange={(e) =>
															setFormMateriId(
																e.target.value
															)
														}
													>
														<option value={0}>
															Pilih Materi
														</option>
														<If
															condition={
																materi.length >
																0
															}
														>
															<Then>
																{materi.map(
																	(
																		m,
																		idx
																	) => {
																		return (
																			<option
																				value={
																					m.id
																				}
																			>
																				{
																					m.judul_materi
																				}
																			</option>
																		);
																	}
																)}
															</Then>
														</If>
													</select>
													{/* <MultiSelect
                            options={optionsMateri}
                            value={formMateriId}
                            onChange={setFormMateriId}
                            labelledBy="Select"
                          /> */}
												</div>
											</div>
										</Then>
									</If>

									<If
										condition={
											jenisFormSection ==
												"sub_modul_ujian_project" ||
											jenisFormSection ==
												"sub_modul_ujian_ujian"
										}
									>
										<Then>
											<div className="form-group grid grid-cols-6">
												<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
													Jenis Section
												</label>
												<div className="flex flex-wrap mt-1 col-span-4">
													<div className="form-check">
														<input
															className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
															type="checkbox"
															checked={
																jenisFormSection ==
																"sub_modul_ujian_project"
																	? true
																	: false
															}
															onClick={() => {
																setJenisFormSection(
																	"sub_modul_ujian_project"
																);
																setFormJenisSubModul(
																	"project"
																);
															}}
														/>
														<label className="form-check-label inline-block text-gray-800">
															Project
														</label>
													</div>
													<div className="form-check ml-3">
														<input
															className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
															type="checkbox"
															checked={
																jenisFormSection ==
																"sub_modul_ujian_ujian"
																	? true
																	: false
															}
															onClick={() => {
																setJenisFormSection(
																	"sub_modul_ujian_ujian"
																);
																setFormJenisSubModul(
																	"ujian"
																);
															}}
														/>
														<label className="form-check-label inline-block text-gray-800">
															Ujian
														</label>
													</div>
												</div>
											</div>
										</Then>
									</If>

									<div className="form-group grid grid-cols-6">
										<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
											Judul
										</label>
										<textarea
											rows={5}
											className="form-control
                                    col-span-4
                                    w-full
                                    px-3
                                    py-1.5
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
											value={formJudul}
											onChange={(e) =>
												setFormJudul(e.target.value)
											}
											placeholder="Masukkan Judul"
										/>
									</div>

									<div className="form-group grid grid-cols-6">
										<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
											Urutan
										</label>
										<input
											type="number"
											className="form-control
                                    col-span-4
                                    w-full
                                    px-3
                                    py-1.5
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
											onChange={(e) =>
												setFormSequence(e.target.value)
											}
											value={formSequence}
											placeholder="Masukkan Urutan"
										/>
									</div>

									<If
										condition={
											jenisFormSection ==
											"sub_modul_ujian_ujian"
										}
									>
										<Then>
											<div className="form-group grid grid-cols-6">
												<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
													Pilih Soal
												</label>
												<div className="col-span-4">
													<Select
														className="w-full"
														options={
															optionsPaketSoal
														}
														value={valuePaketSoal}
														onChange={(val: any) =>
															setFormPaketSoalId(
																val.value
															)
														}
														placeholder="Pilih Soal"
													/>
												</div>
											</div>

											<div className="form-group grid grid-cols-6">
												<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
													Durasi Ujian
												</label>
												<input
													type="number"
													min={1}
													className="form-control
                          col-span-4
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
													placeholder="Masukkan Durasi (Menit)"
													onChange={(e) =>
														setFormSubModulDurasiLatihan(
															e.target.value
														)
													}
													value={
														formSubModulDurasiLatihan
													}
												/>
											</div>

											<div className="form-group grid grid-cols-6">
												<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
													Passing Grade
												</label>
												<input
													type="number"
													min={1}
													max={100}
													className="form-control
                          col-span-4
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
													onChange={(e) =>
														setFormSubModulPassingGrade(
															e.target.value
														)
													}
													value={
														formSubModulPassingGrade
													}
													placeholder="Masukkan Passing Grade"
												/>
											</div>

											<div className="form-group grid grid-cols-6">
												<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
													Limit Soal
												</label>
												<input
													type="number"
													min={1}
													max={100}
													className="form-control
                          col-span-4
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
													onChange={(e) =>
														setFormLimitSoal(
															e.target.value
														)
													}
													value={formLimitSoal}
													placeholder="Masukkan Limit Soal"
												/>
											</div>
										</Then>
									</If>
								</div>
								<div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
										onClick={() =>
											setShowDialogUpdate(false)
										}
									>
										Batal
									</button>
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
										onClick={() => actionUpdate()}
									>
										<If condition={formLoading}>
											<Then>
												<ReactLoading
													type={"bars"}
													color={"white"}
													height={15}
													width={20}
												/>
											</Then>
											<Else>Simpan</Else>
										</If>
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

const ModalDelete: React.FC = () => {
	const { showDialogDelete, formLoading } =
		useKelolaKontenManajemenPelatihanContext().state;
	const { setShowDialogDelete, deleteModulPelatihan } =
		useKelolaKontenManajemenPelatihanContext();

	return (
		<>
			{showDialogDelete ? (
				<>
					{/* Background Modal */}
					<div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

					{/* Modal */}
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
							<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
								{/* Modal content */}
								<div className="flex items-start justify-between p-4 rounded-t">
									<h3 className="text-xl font-semibold text-gray-900">
										Hapus Section
									</h3>
									<button
										type="button"
										className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
										onClick={() =>
											setShowDialogDelete(false)
										}
										style={{ color: "white" }}
									>
										<svg
											className="w-5 h-5"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									</button>
								</div>
								<div className="p-6 space-y-6 -mt-2">
									Apakah Anda Yakin Ingin Menghapus Data Ini?
								</div>
								<div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
										onClick={() =>
											setShowDialogDelete(false)
										}
									>
										Batal
									</button>
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
										onClick={() => deleteModulPelatihan()}
									>
										<If condition={formLoading}>
											<Then>
												<ReactLoading
													type={"bars"}
													color={"white"}
													height={15}
													width={20}
												/>
											</Then>
											<Else>Yakin</Else>
										</If>
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

const ModalCreateDataQuiz: React.FC = () => {
	const {
		typeDialogFormQuiz,
		formLoading,
		showDialogQuiz,
		selectedQuiz,
		formPertanyaan,
	} = useKelolaKontenManajemenPelatihanContext().state;
	const {
		actionFormDialogQuiz,
		setShowDialogQuiz,
		setFormPertanyaanQuiz,
		setFormWaktuKe,
	} = useKelolaKontenManajemenPelatihanContext();
	const opsiArray = ["a", "b", "c", "d", "e"];

	return (
		<>
			{showDialogQuiz ? (
				<>
					{/* Background Modal */}
					<div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

					{/* Modal */}
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
							<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
								{/* Modal content */}
								<div className="flex items-start justify-between p-4 rounded-t">
									<h3 className="text-xl font-semibold text-gray-900">
										{typeDialogFormQuiz} Quiz
									</h3>
									<button
										type="button"
										className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
										onClick={() => setShowDialogQuiz(false)}
										style={{ color: "white" }}
									>
										<svg
											className="w-5 h-5"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									</button>
								</div>
								<div className="p-6 space-y-6 -mt-2 overflow-y-auto h-96">
									<div className="form-group mb-6">
										<label className="form-label inline-block mb-2 text-gray-700">
											Waktu Ke (Menit)
										</label>
										<div className="w-full">
											<input
												type="time"
												className="form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
												defaultValue={
													selectedQuiz != null
														? selectedQuiz.waktu_ke
														: ""
												}
												onChange={(e) =>
													setFormWaktuKe(
														e.target.value + ":00"
													)
												}
												placeholder="Waktu Ke (Menit)"
											/>
										</div>
									</div>

									<div className="form-group mb-6">
										<label className="form-label inline-block mb-2 text-gray-700">
											Pertanyaan
										</label>
										<div className="w-full">
											<EditorComponent
												description={
													selectedQuiz != null
														? selectedQuiz.pertanyaan
														: formPertanyaan
												}
												setDescription={(a) =>
													setFormPertanyaanQuiz(a)
												}
												withKey={false}
											/>
										</div>
									</div>

									<FormItemOpsi opsi={opsiArray} />
								</div>
								<div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
										onClick={() => setShowDialogQuiz(false)}
									>
										Batal
									</button>
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
										onClick={() => actionFormDialogQuiz()}
									>
										<If condition={formLoading}>
											<Then>
												<ReactLoading
													type={"bars"}
													color={"white"}
													height={15}
													width={20}
												/>
											</Then>
											<Else>Simpan</Else>
										</If>
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

const ModalDeleteQuiz: React.FC = () => {
	const { showDialogDeleteQuiz, formLoading } =
		useKelolaKontenManajemenPelatihanContext().state;
	const { setShowDialogDeleteQuiz, deleteQuiz } =
		useKelolaKontenManajemenPelatihanContext();

	return (
		<>
			{showDialogDeleteQuiz ? (
				<>
					<div className="modal  justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-100 outline-none focus:outline-none">
						<div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-non w-full ">
							{/*content*/}
							<div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
								{/*header*/}
								<div className="modal-header flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
									<h5
										className="text-base font-medium leading-normal text-gray-800"
										id="exampleModalScrollableLabel"
									>
										Hapus Section
									</h5>
									<a
										onClick={() =>
											setShowDialogDeleteQuiz(false)
										}
										className="cursor-pointer"
									>
										<AiFillCloseCircle
											style={{
												color: "#F67070",
												fontSize: "25px",
											}}
										/>
									</a>
								</div>
								{/*body*/}
								<div className="modal-body relative p-6">
									Apakah Anda Yakin Ingin Menghapus Data Ini?
								</div>
								{/*footer*/}
								<div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
										onClick={() =>
											setShowDialogDeleteQuiz(false)
										}
									>
										Batal
									</button>
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
										onClick={() => deleteQuiz()}
									>
										<If condition={formLoading}>
											<Then>
												<ReactLoading
													type={"bars"}
													color={"white"}
													height={15}
													width={20}
												/>
											</Then>
											<Else>Yakin</Else>
										</If>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-50 bg-black w-full "></div>
				</>
			) : null}
		</>
	);
};

const ModalCreateDataLatihan: React.FC = () => {
	const { formLoading, showDialogLatihan, paketSoal, optionsPaketSoal } =
		useKelolaKontenManajemenPelatihanContext().state;
	const {
		actionFormDialogLatihan,
		setShowDialogLatihan,
		setFormPaketSoalIdLatihan,
		setFormDurasiLatihan,
		setFormPassingGradeLatihan,
		setFormLimitPengulangan,
		setDefaultFormLatihan,
		setFormLimitSoal,
	} = useKelolaKontenManajemenPelatihanContext();

	return (
		<>
			{showDialogLatihan ? (
				<>
					{/* Background Modal */}
					<div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

					{/* Modal */}
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
							<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
								{/* Modal content */}
								<div className="flex items-start justify-between p-4 rounded-t">
									<h3 className="text-xl font-semibold text-gray-900">
										Tambah Latihan
									</h3>
									<button
										type="button"
										className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
										onClick={() => {
											setShowDialogLatihan(false);
											setDefaultFormLatihan();
										}}
										style={{ color: "white" }}
									>
										<svg
											className="w-5 h-5"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									</button>
								</div>
								<div className="p-6 space-y-6 -mt-2">
									<div className="form-group grid grid-cols-6">
										<label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
											Pilih Soal
										</label>
										<div className="col-span-4">
											<Select
												className="w-full"
												options={optionsPaketSoal}
												onChange={(val: any) =>
													setFormPaketSoalIdLatihan(
														val.value
													)
												}
												placeholder="Pilih Soal"
											/>
										</div>
									</div>

									<div className="form-group grid grid-cols-6 mt-5">
										<label className="form-label inline-block mb-2 text-gray-700 font-medium col-span-2">
											Durasi Latihan
										</label>
										<input
											type="number"
											className="form-control
                            col-span-4
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
											onChange={(e) =>
												setFormDurasiLatihan(
													e.target.value
												)
											}
											placeholder="(Menit)"
										/>
									</div>

									<div className="form-group grid grid-cols-6">
										<label className="form-label inline-block mb-2 text-gray-700 font-medium col-span-2">
											Passing Grade
										</label>
										<input
											type="number"
											className="form-control
                      col-span-4
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
											onChange={(e) =>
												setFormPassingGradeLatihan(
													e.target.value
												)
											}
											placeholder="Passing Grade"
										/>
									</div>
									<div className="form-group grid grid-cols-6">
										<label className="form-label inline-block mb-2 text-gray-700 font-medium col-span-2">
											Limit Pengulangan
										</label>
										<input
											type="number"
											className="form-control
                      col-span-4
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
											onChange={(e) =>
												setFormLimitPengulangan(
													e.target.value
												)
											}
											placeholder="(Angka)"
										/>
									</div>
									<div className="form-group grid grid-cols-6">
										<label className="form-label inline-block mb-2 text-gray-700 font-medium col-span-2">
											Limit Soal
										</label>
										<input
											type="number"
											className="form-control
                      col-span-4
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
											onChange={(e) =>
												setFormLimitSoal(e.target.value)
											}
											placeholder="(Angka)"
										/>
									</div>
								</div>
								<div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-m col-span-2d hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
										onClick={() => {
											setShowDialogLatihan(false);
											setDefaultFormLatihan();
										}}
									>
										Batal
									</button>
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
										onClick={() => {
											actionFormDialogLatihan("Tambah");
										}}
									>
										<If condition={formLoading}>
											<Then>
												<ReactLoading
													type={"bars"}
													color={"white"}
													height={15}
													width={20}
												/>
											</Then>
											<Else>Simpan</Else>
										</If>
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

const ModalDeleteLatihan: React.FC = () => {
	const { showDialogDeleteLatihan, formLoading } =
		useKelolaKontenManajemenPelatihanContext().state;
	const { setShowDialogDeleteLatihan, deleteLatihan } =
		useKelolaKontenManajemenPelatihanContext();

	return (
		<>
			{showDialogDeleteLatihan ? (
				<>
					{/* Background Modal */}
					<div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

					{/* Modal */}
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
							<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
								{/* Modal content */}
								<div className="flex items-start justify-between p-4 rounded-t">
									<h3 className="text-xl font-semibold text-gray-900">
										Hapus Latihan
									</h3>
									<button
										type="button"
										className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
										onClick={() =>
											setShowDialogDeleteLatihan(false)
										}
										style={{ color: "white" }}
									>
										<svg
											className="w-5 h-5"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									</button>
								</div>
								<div className="p-6 space-y-6 -mt-2">
									Apakah Anda Yakin Ingin Menghapus Data
									Latihan di Materi Ini?
								</div>
								<div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
										onClick={() =>
											setShowDialogDeleteLatihan(false)
										}
									>
										Batal
									</button>
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
										onClick={() => deleteLatihan()}
									>
										<If condition={formLoading}>
											<Then>
												<ReactLoading
													type={"bars"}
													color={"white"}
													height={15}
													width={20}
												/>
											</Then>
											<Else>Yakin</Else>
										</If>
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

const ModalDetailSoalLatihan: React.FC = () => {
	const { showModalDetailSoalLatihan, paketSoal, IndexPaketSoalIdLatihan } =
		useKelolaKontenManajemenPelatihanContext().state;
	const { setShowModalDetailSoalLatihan } =
		useKelolaKontenManajemenPelatihanContext();

	return (
		<>
			{showModalDetailSoalLatihan ? (
				<>
					{/* Background Modal */}
					<div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

					{/* Modal */}
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
							<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
								{/* Modal content */}
								<div className="flex items-start justify-between p-4 rounded-t">
									<h3 className="text-xl font-semibold text-gray-900">
										Detail Soal Latihan
									</h3>
									<button
										type="button"
										className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
										onClick={() =>
											setShowModalDetailSoalLatihan(false)
										}
										style={{ color: "white" }}
									>
										<svg
											className="w-5 h-5"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									</button>
								</div>
								<div className="p-6 space-y-6 -mt-2 overflow-y-auto h-96">
									{paketSoal[
										IndexPaketSoalIdLatihan
									].soal.map((s, i) => {
										return (
											<ItemSoal
												idAccordion={"Soal" + (i + 1)}
												nomor={i + 1}
												soal={s.soal}
												jawaban={s.jawaban}
											/>
										);
									})}
								</div>
								<div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
									<button
										type="button"
										className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
										onClick={() =>
											setShowModalDetailSoalLatihan(false)
										}
									>
										Tutup
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

const WrapperMain: React.FC = () => {
	const { goToPenilaianStep } = useKelolaKontenManajemenPelatihanContext();

	return (
		<div className="app">
			<ModalCreateData />
			<ModalUpdateData />
			<ModalDelete />
			<ModalCreateDataQuiz />
			<ModalDeleteQuiz />
			<ModalCreateDataLatihan />
			<ModalDeleteLatihan />
			<ModalDetailSoalLatihan />
			<main style={{ padding: "0px" }}>
				<header className="sticky top-0 z-40">
					<BottomHeader />
				</header>

				<div className="container px-4 md:px-12 mb-10">
					<div className="bg-white rounded-lg ">
						<ul
							className="stepper pb-3 rounded-lg"
							data-mdb-stepper="stepper"
						>
							<li className="stepper-step">
								<a
									href={`/admin/ubah/manajemen_pelatihan/${
										window.location.pathname.split(
											"/",
											5
										)[4]
									}`}
								>
									<div className="stepper-head">
										<span
											className="stepper-head-icon font-semibold"
											style={{
												width: "37px",
												height: "37px",
												backgroundColor: "#0DBD7F",
											}}
										>
											01
										</span>
										<span className="stepper-head-text font-semibold">
											Data Pelatihan
										</span>
									</div>
								</a>
							</li>
							<li className="stepper-step">
								<div className="stepper-head">
									<span
										className="stepper-head-icon font-semibold"
										style={{
											width: "37px",
											height: "37px",
											backgroundColor: "#0DBD7F",
										}}
									>
										02
									</span>
									<span className="stepper-head-text font-semibold">
										Kelola Konten
									</span>
								</div>
							</li>
							<li className="stepper-step">
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										goToPenilaianStep();
									}}
								>
									<div className="stepper-head">
										<span
											className="stepper-head-icon font-semibold"
											style={{
												width: "37px",
												height: "37px",
											}}
										>
											03
										</span>
										<span className="stepper-head-text font-semibold">
											Penilaian
										</span>
									</div>
								</a>
							</li>
						</ul>
					</div>
					<Konten />
				</div>
			</main>
		</div>
	);
};

const KelolaKontenManajemenPelatihan: React.FC = () => {
	return (
		<KelolaKontenManajemenPelatihanController.Provider>
			<ToastContainer
				position="top-center"
				style={{ width: "60%", marginTop: "5%" }}
			/>
			<SidebarWithHeader title="Manajemen Pelatihan">
				<WrapperMain />
			</SidebarWithHeader>
		</KelolaKontenManajemenPelatihanController.Provider>
	);
};

export default KelolaKontenManajemenPelatihan;
