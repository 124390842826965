import { Box, Text, Image, Flex, Skeleton } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

interface CardItemProps {
  item?: any;
  image: string;
}

/**
 * Renders a card item component for the admin pengetahuan page.
 *
 * @param {CardItemProps} props - The props for the CardItem component.
 * @param {Object} props.item - The item object containing information about the card.
 * @param {string} props.item.id - The ID of the item.
 * @param {string} props.item.title - The title of the item.
 * @param {string} props.image - The URL of the image to display on the card.
 * @return {JSX.Element} The rendered CardItem component.
 * @author Bagas Prasetyadi
 */

const CardItem = ({ item, image }: CardItemProps) => {
  const history = useHistory();
  return (
    <Box
      boxShadow="lg"
      borderRadius="lg"
      overflow="hidden"
      cursor="pointer"
      onClick={() => history.push(`/admin/manajemen_pengetahuan/${item.id}`)}
      _hover={{
        transform: "scale(1.01)",
        boxShadow: "xl",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
      }}
    >
      <Box position="relative">
        <Image
          objectFit="cover"
          fallback={<Skeleton w="full" h="full" />}
          src={image}
          alt="thumbnail"
          w="full"
          borderTopRadius="lg"
          h="200px"
        />
      </Box>
      <Box p={4} bg="white" borderBottomRadius="lg">
        <Flex justifyContent="space-between" alignItems="center">
          <Text
            fontSize="md"
            fontWeight="semibold"
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              lineClamp: 1,
              WebkitLineClamp: 1,
            }}
          >
            {item?.title}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default CardItem;
