import React from "react";
import { toast } from "react-toastify";
import { container } from "tsyringe";
import { ExamPresenter } from "../../../../../data/presenters/ExamPresenter";
import { Exam } from "../../../../../entities/Exam";
import update from "immutability-helper";
import { useToast } from "@chakra-ui/react";
import axios from "axios";

interface IState {
  isLoading: boolean;
  isLoadingStartLatihan: boolean;
  latihan: Exam | null;
  soalDipilih: string;
  bankSoal: any;
  bankJawaban: any;
  indexSoal: number;
  jawabanPeserta: string;
  duration: number;
  sequence: string;
  totalSequence: string;
  showModalFinishLatihan: boolean;
  showModalReattempt: boolean;
  isSavingAnswer: boolean;
  isLoadingFinishLatihan: boolean;
}

interface InitialState {
  state: IState;
  getLatihan: Function;
  startLatihan: Function;
  setIndexSoal: Function;
  setSoalDipilih: Function;
  setBankJawaban: Function;
  onChangeAnswer: Function;
  setJawabanPeserta: Function;
  setTime: Function;
  finishLatihan: Function;
  reattemptLatihan: Function;
  setShowModalFinishLatihan: Function;
  setShowModalReattempt: Function;
}

const initialState = {
  state: {
    isLoading: true,
    isLoadingStartLatihan: true,
    latihan: null,
    soalDipilih: "",
    bankSoal: [],
    bankJawaban: [],
    indexSoal: 0,
    jawabanPeserta: "",
    duration: 0,
    sequence: "",
    totalSequence: "",
    showModalFinishLatihan: false,
    showModalReattempt: false,
    isSavingAnswer: false,
    isLoadingFinishLatihan: false,
  },
  getLatihan: () => {},
  startLatihan: () => {},
  setIndexSoal: () => {},
  setSoalDipilih: () => {},
  setBankJawaban: () => {},
  onChangeAnswer: () => {},
  setJawabanPeserta: () => {},
  setTime: () => {},
  finishLatihan: () => {},
  reattemptLatihan: () => {},
  setShowModalFinishLatihan: () => {},
  setShowModalReattempt: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: LatihanProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    isLoadingStartLatihan: false,
    latihan: null,
    soalDipilih: "",
    bankSoal: [],
    bankJawaban: [],
    indexSoal: 0,
    jawabanPeserta: "",
    duration: 0,
    sequence: "",
    totalSequence: "",
    showModalFinishLatihan: false,
    showModalReattempt: false,
    isSavingAnswer: false,
    isLoadingFinishLatihan: false,
  });

  const toastChakraUi = useToast();

  const setShowModalReattempt = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showModalReattempt: value,
    }));
  };
  const setShowModalFinishLatihan = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showModalFinishLatihan: value,
    }));
  };

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setLoadingStartLatihan = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingStartLatihan: value,
    }));
  };

  const setIndexSoal = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      indexSoal: value,
    }));
  };

  const setSoalDipilih = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      soalDipilih: value,
    }));
  };

  const setBankJawaban = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      bankJawaban: value,
    }));
  };

  const setJawabanPeserta = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      jawabanPeserta: value,
    }));
  };

  const setIsSavingAnswer = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isSavingAnswer: value,
    }));
  };

  const setIsLoadingFinishLatihan = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingFinishLatihan: value,
    }));
  };

  const startLatihan = async (latihan_id: number) => {
    setLoadingStartLatihan(true);

    try {
      const examPresenter = container.resolve(ExamPresenter);
      await examPresenter.startLatihan(latihan_id);

      window.location.href = "/latihan/" + latihan_id;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorMessage =
          error.response?.data &&
          typeof error.response.data === "object" &&
          "message" in error.response.data
            ? (error.response.data as { message: string }).message
            : error.message || "Terjadi kesalahan.";

        setLoadingStartLatihan(false);

        return toastChakraUi({
          title: errorMessage,
          status: "error",
          position: "top-right",
          isClosable: true,
        });
      }

      console.error("error latihan:", error);
      toast.error(`Error saat memulai latihan : ${error}`, {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoadingStartLatihan(false);
    }
  };

  const getLatihan = async (latihan_id: number) => {
    setLoading(true);

    try {
      const examPresenter = container.resolve(ExamPresenter);
      const getLatihan = await examPresenter.getLatihan(latihan_id);

      const data = getLatihan;

      setState((prevstate) => ({
        ...prevstate,
        latihan: data,
        soalDipilih: data.m_soal[0].soal,
        bankSoal: data.m_soal,
        bankJawaban: data.m_soal[0].m_jawaban_soal,
        jawabanPeserta: data.m_soal[0].jawaban_peserta,
        duration: data.sisa_waktu,
        sequence: data.sequence,
        totalSequence: data.total_sequence,
      }));

      localStorage.setItem("passing_grade", data.passing_grade);
      localStorage.setItem("judul_sub_modul", data.judul_sub_modul);

      setLoading(false);
    } catch (error: any) {
      console.log("error latihan:", error);
      setLoading(false);
    }
  };

  const onChangeAnswer = async (id_jawaban: string) => {
    const response = await saveAnswer(id_jawaban);

    if (response.code === 200) {
      const updatedData = update(state.bankSoal[state.indexSoal], {
        jawaban_peserta: { $set: id_jawaban },
      });

      const newData = update(state.bankSoal, {
        $splice: [[state.indexSoal, 1, updatedData]],
      });

      setState((prevstate) => ({
        ...prevstate,
        bankSoal: newData,
        jawabanPeserta: id_jawaban,
      }));
    }

    return;
  };

  const saveAnswer = async (value: string) => {
    setIsSavingAnswer(true);

    try {
      const examPresenter = container.resolve(ExamPresenter);
      const response = await examPresenter.answerLatihan(
        state.bankSoal[state.indexSoal].id,
        state.bankSoal[state.indexSoal].id_jawaban_latihan,
        value
      );
      return response.data;
    } catch (error: any) {
      toast.error(`Error saat menyimpan jawaban : ${error}`, {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      console.log("error save answer:", error);
    } finally {
      setIsSavingAnswer(false);
    }
  };

  const setTime = async (latihan_id: number) => {
    try {
      const examPresenter = container.resolve(ExamPresenter);
      await examPresenter.updateWaktu(latihan_id);
    } catch (error: any) {
      console.log("error update duration:", error.message);
    }
  };

  const finishLatihan = async (latihan_id: number) => {
    setIsLoadingFinishLatihan(true);

    if (state.isSavingAnswer) {
      return;
    }

    try {
      const examPresenter = container.resolve(ExamPresenter);
      const finishLatihan = await examPresenter.finishLatihan(latihan_id);
      const response = finishLatihan;

      const data = response.data.data;

      localStorage.setItem("nilai_peserta", data.nilai);

      window.location.href = "/skor/" + latihan_id;
    } catch (error: any) {
      setIsLoadingFinishLatihan(false);
      console.log("error finish latihan:", error.message);
    }
  };

  const reattemptLatihan = async (latihan_id: number) => {
    try {
      const examPresenter = container.resolve(ExamPresenter);
      const reattemptLatihan = await examPresenter.reattempLatihan(latihan_id);
      const response = reattemptLatihan;

      const data = response;

      return data.data.code;
    } catch (error: any) {
      console.log("error reattempt latihan:", error.message);
    }
  };

  return (
    <LatihanProvider
      value={{
        state,
        getLatihan,
        startLatihan,
        setIndexSoal,
        setSoalDipilih,
        setBankJawaban,
        onChangeAnswer,
        setJawabanPeserta,
        setTime,
        finishLatihan,
        reattemptLatihan,
        setShowModalFinishLatihan,
        setShowModalReattempt,
      }}
    >
      {children}
    </LatihanProvider>
  );
};

export const useLatihanContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useLatihanContext,
  Provider,
};
