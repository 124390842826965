import React from "react";
import axiosInstance from "../../../../api/axiosInstance";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";

interface EditorProps {
	description: string;
	setDescription: Function;
	keyState?: string;
	bgColor?: string;
	withKey?: boolean;
}

/**
 * Render the Editor component.
 *
 * @param {EditorProps} props - The props for the Editor component.
 * @param {string} props.description - The description value.
 * @param {Function} props.setDescription - The function to set the description value.
 * @return {JSX.Element} The rendered Editor component.
 * @author Bagas Prasetyadi
 */

const EditorComponent = ({
	description,
	setDescription,
	keyState = "description",
	bgColor = "#F6F5F8",
	withKey = true,
}: EditorProps) => {
	const quillRef = React.useRef<ReactQuill | null>(null);

	const imageHandler = async () => {
		const input = document.createElement("input");
		input.type = "file";
		input.accept = "image/*";
		input.click();

		input.onchange = async () => {
			if (!input.files || input.files.length === 0) {
				console.error("No file selected");
				return;
			}

			const file = input.files[0];
			const formData = new FormData();
			formData.append("image", file);

			try {
				const response = await axiosInstance.post(
					"/public/upload",
					formData
				);

				const imageUrl = response.data.data;
				const quill = quillRef.current?.getEditor(); // Pastikan ini aman
				if (quill) {
					const range = quill.getSelection();
					quill.insertEmbed(range?.index || 0, "image", imageUrl);
				}
			} catch (error) {
				console.error("Error uploading image:", error);
			}
		};
	};

	const modules = React.useMemo(
		() => ({
			toolbar: {
				container: [
					[{ header: [1, 2, 3, 4, 5, false] }],
					["bold", "italic", "underline"],
					[{ align: [] }],
					[{ list: "ordered" }, { list: "bullet" }],
					[{ indent: "-1" }, { indent: "+1" }],
					["image"],
				],
				handlers: {
					image: imageHandler,
				},
				clipboard: {
					matchVisual: false,
				},
			},
		}),
		[]
	);

	return (
		<ReactQuill
			ref={quillRef}
			theme={`snow`}
			onChange={(val) =>
				withKey ? setDescription(keyState, val) : setDescription(val)
			}
			value={description}
			modules={modules}
		/>
	);
};

export default EditorComponent;
